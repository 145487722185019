const hostList = [
  { host : "lotto999.in" , matomoId : "16" , clarityId : "kq9mdu7z60" , googleId : "G-6W84H3MRMT" },
  { host : "lotto990.com" , matomoId : "7" , clarityId : "kggpi3fqn9" , googleId : "G-V4C67K5K9H" },
  { host : "lotto991.com" , matomoId : "8" , clarityId : "ndav0uax8g" , googleId : "G-3RFKZ32PTY" },
  { host : "lotto992.com" , matomoId : "9" , clarityId : "ndavfdd67l" , googleId : "G-FZTX1S3JDN" },
  { host : "lotto993.com" , matomoId : "10" , clarityId : "ndavtkr76c" , googleId : "G-KSE0QVQQ47" },
  { host : "lotto994.com" , matomoId : "1" , clarityId : "ndb0x6yrsy" , googleId : "G-TTM8W4GKTK" },
  { host : "lotto995.com" , matomoId : "3" , clarityId : "ndb14jcwpi" , googleId : "G-JT4DML2BWP" },
  { host : "lotto996.com" , matomoId : "4" , clarityId : "ndb1do5zml" , googleId : "G-D2013EMMTD" },
  { host : "lotto999.cc" , matomoId : "6" , clarityId : "ndb1priqzs" , googleId : "G-92H4Y2J6SP" },
  { host : "lottery993.com" , matomoId : "32" , clarityId : "ndb20ez0wg" , googleId : "G-V4C67K5K9H" },
  { host : "lottery994.com" , matomoId : "33" , clarityId : "ndb28fetu7" , googleId : "G-X4DW7M34QT" },
  { host : "lotto999.app" , matomoId : "34" , clarityId : "" , googleId : "" },
  { host : "lotto999.bet" , matomoId : "2" , clarityId : "ndb2hhe9yq" , googleId : "G-P4EE7WGQRS" },
  { host : "lotto997.com" , matomoId : "5" , clarityId : "ndb2rdd5rg" , googleId : "G-GFS87WRWCW" },
]
const hostData = hostList.find(item=>location.hostname.includes(item.host))


if(hostData?.matomoId){
  const matomoScript = document.createElement("script")
  matomoScript.innerHTML = `
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["setCookieDomain", "*.${hostData.host}"]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
  var u="https://f1787.com/";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '${hostData.matomoId}']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
  `
  document.getElementsByTagName("head")[0].appendChild(matomoScript)
}


if(hostData?.clarityId){
  const clarityScript = document.createElement("script")
  clarityScript.innerHTML = `
  (function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${hostData.clarityId}");
  `
  document.getElementsByTagName("head")[0].appendChild(clarityScript)
}



if(hostData?.googleId){
  const googleScript = document.createElement("script")
  const googleLinkScript = document.createElement("script")
  googleLinkScript.src = `https://www.googletagmanager.com/gtag/js?id=${hostData.googleId}`
  googleLinkScript.async = true
  googleScript.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${hostData.googleId}');
  `
  document.getElementsByTagName("head")[0].appendChild(googleLinkScript)
  document.getElementsByTagName("head")[0].appendChild(googleScript)
}
